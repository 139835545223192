<template>
  <div class="index">
    <div class="title-serch">
<!--      <span style="white-space: nowrap;margin-left: 10px;color:#1890ff">-->
<!--        团队-->
<!--        <a-icon type="swap"/>-->
<!--      </span>-->
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="搜索活码名称"
          shape="round"
          @search="onSearch"
          @clear="clearSerch"
      >
        <template #action >
          <div @click="routerPush('/channelCode/create')" style="    display: flex;
    align-items: center; color:#2b60dd;    font-size: 26px;">
            <van-icon style="font-size: 26px" name="add" />
          </div>
        </template>
      </van-search>
    </div>
    <div class="content">
      <div>
      </div>

      <div class="item">
        <div class="title bet">
          <div>共{{codeList.length}}个结果</div>
          <div class="bets" @click="">
            <span @click="$refs.grouping.$show()">{{ groupName.name ? groupName.name : '全部' }}</span>
            <van-icon style="    float: right;
    margin-right: -5px;
    margin-left: 5px;" @click="clear" v-if="groupName.name" name="close" />
            <van-icon style="    float: right;
    margin-right: -5px;
    margin-left: 5px;" @click="$refs.grouping.$show()" v-else name="arrow-down" />
          </div>
        </div>
        <div class="item-body">

          <div v-if="codeList.length > 0">
            <div @click.stop="detailItem(item)" v-for="(item,index) in codeList" :key="index" class="body-item">
              <div style="    width: 69%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;">
                <span>{{ item.name }}</span>
              </div>
              <div>
                扫码添加的人数：{{item.contactNum}}人
              </div>
              <div style="display: flex">
                渠道标签：
                <p class="main-agency">{{String(item.tags)}}</p>
              </div>
              <div style="    max-width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;">
                使用成员：<span v-for="(i,is) in item.employees" :key="is">{{i.name}} </span>
              </div>
              <div class="operation">
                {{ item.createdAt }}
                <div style="    display: flex;
    align-items: center;
    justify-content: space-between;">
                  <p @click.stop="editCode(item)" style="margin-right: 10px !important">
                    <van-icon name="edit" />
                    编辑
                  </p>
                  <p @click.stop="removeCode(item)">
                    <van-icon name="delete-o" />
                    删除
                  </p>
                </div>
              </div>
              <div class="img">
                <img :src="item.qrcodeUrl" alt="">
                <img v-if="item.fullImage" :src="item.fullImage" alt="">
              </div>
            </div>
          </div>
          <van-empty
              v-else
              class="custom-image"
              :image="require('../../assets/images/search-no-data.svg')"
              description="没有找到渠道码"
          />
        </div>
      </div>
    </div>
<!--  分组  -->
    <grouping ref="grouping" @change="groupDate"/>
  </div>
</template>

<script>
import grouping from "./components/grouping";
import {channelCodeIndex, channelCodeRemove} from "../../api/channelCode";
export default {
  components:{
    grouping
  },
  data() {
    return {
      // 文字
      serchValue: '',
      message: '',
      // tbas切换
      tabsChose: 0,
      // 分组名称
      groupName: '',
      // 数据
      codeList: []
    }
  },
  watch: {},
  created() {
    this.codeData()
    },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    routerPush(e) {
      this.$router.push(e)
    },
    // 搜索
    onSearch(){
      this.codeData(this.serchValue)
    },
    clearSerch(){
      this.serchValue = ''
      this.codeData()
    },
    // 删除
    removeCode(item){
      channelCodeRemove({channelCodeId: item.id}).then(res=>{
        this.$message.success('删除成功')
        this.codeData()
      })
    },
    //清除
    clear(){
      this.groupName = ''
      this.codeData()
    },
    // 获取分组
    groupDate(data){
      this.groupName = data
      this.codeData()
    },
    // 获取数据
    codeData(name){
      channelCodeIndex({
        groupId: this.groupName.groupId ? this.groupName.groupId : 0,
        name
      }).then(res=>{
        this.codeList = res.data
      })
    },

    // 编辑
    editCode(item){
      this.$router.push({
        path:"/channelCode/create",
        query:{
          id: item.channelCodeId
        }
      })
    },
    // 詳情
    detailItem(i){
      this.$router.push({
        path: '/channelCode/detail',
        query: {
          item: JSON.stringify(i)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-empty__image {
  width: 129px !important;
  height: 115px !important;
}
/deep/.van-tabs__line{
  bottom: 9px;
  margin-top: 0;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 1px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  background: #F1f2f5;
}

/deep/.van-empty__image img{
  object-fit: contain;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }
  .main-agency {
    //width: 280px;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //display: -webkit-box;
    //-webkit-box-orient: vertical;
    //-webkit-line-clamp: 2;
    width: 150px;
    /* height: 127px; */
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: inline-block;
    text-overflow: ellipsis;
  }
  .fs17 {
    font-size: 17px;
  }

  .fs14 {
    font-size: .14px;
  }

  .title-serch{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  .bets{
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 86px;
    /* background: red; */
    height: 25px !important;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    span{
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 5px;
      display: block;
    }
  }
  .bet{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content {
    background: #fff;
    min-height: 92%;
    //padding-top: 5px;
    margin-top: -15px;

    img {
      width: 22px;
      height: 22px;
    }
    .item{
        padding: 12px 16px;
      width: 100%;
        .title{
          width: 100%;
          font-size: 12px;
          div:nth-child(1){
            color: #444;
            font-size: 12px;
          }
          img{
            width: 14px;
            height: 14px;
          }
        }
        .item-body{
          .body-item{
            background: #f4f5f7;
            padding: 18px 15px;
            margin-top: 10px;
            border-radius: 12px;
            font-size: 12px;
            color: #585757;
            position: relative;
            div{
              margin-bottom: 5px;
            }
            div:nth-child(1){
              color: #444;
              margin-bottom: 10px;
              span{
                background: #c277ff;
                color: #fff;
                padding: 2px 3px;
                border-radius: 5px;
                font-size: 12px;
                margin-right: 9px;
              }
            }
            div:last-child{
              margin-top: 10px;
              color: #939393;
            }
            .img{
              position: absolute;
              right: 14px;
              width: 90px;
              height: 90px;
              top: 47%;
              margin: 0 !important;
              padding: 0 !important;
              transform: translateY(-50%);
              img:nth-child(1){
                width: 100%;
                height: 100%;
              }
              img:nth-child(2){
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
              }
            }
            .operation{
              margin-bottom: -9px;
              margin-top: 12px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
    }
  }
}
</style>
