import request from "../plugins/axios";

// 个人客户统计
export function contactStatistics(params) {
    return request({
        url: '/workContact/contactStatistics',
        method: 'GET',
        params
    })
}

// 个人群统计
export function roomStatistics(params) {
    return request({
        url: '/workRoom/roomStatistics',
        method: 'GET',
        params
    })
}

// 管理员客户统计
export function departmentStatistics(params) {
    return request({
        url: '/workContact/departmentStatistics',
        method: 'GET',
        params
    })
}

// 管理员客户群统计
export function departmentRoomStatistics(params) {
    return request({
        url: '/workRoom/departmentRoomStatistics',
        method: 'GET',
        params
    })
}

// 管理员员工拉新排行
export function employeeRanking(params) {
    return request({
        url: '/workContact/employeeRanking',
        method: 'GET',
        params
    })
}

export function statisticsCode(params) {
    return request({
        url: '/channelCode/statistics',
        method: 'GET',
        params
    })
}

export function statisticsIndexCode(params) {
    return request({
        url: '/channelCode/statisticsIndex',
        method: 'GET',
        params
    })
}

// 员工客户统计
export function employeeContactStatistics(params) {
    return request({
        url: '/workContact/employeeContactStatistics',
        method: 'GET',
        params
    })
}
